.App {
  height: 100%;
  padding: .5rem;
  display: grid;
  grid-template: "title message load .    save" auto
                 "text  text    text text text" 1fr
               / auto   1fr     auto 1rem auto;
  gap: .5rem .1rem;
}

h1.title {
  grid-area: title;
  align-self: center;  /* Grid 内でこの要素のみ垂直中央揃えにする (親要素に指定する align-items の子要素版) */
  margin: 0;
  font-size: 1rem;
}

h1.title a {
  color: inherit;
  text-decoration: none;
}

h1.title a:hover,
h1.title a:focus {
  text-decoration: underline;
}

div.message {
  grid-area: message;
  align-self: center;
  overflow: hidden;
  color: #666;
  font-size: .8rem;
  text-align: center;
  white-space: nowrap;
  user-select: none;
}

button.load {
  grid-area: load;
  align-self: center;
}

button.save {
  grid-area: save;
  align-self: center;
}

textarea.text {
  grid-area: text;
  min-height: 5rem;
  resize: none;
}
