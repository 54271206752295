*, ::before, ::after {
  box-sizing: border-box;
}

html, textarea {
  font-family: "Noto Sans Mono CJK JP", Osaka-mono, "MS Gothic", Menlo, Consolas, Courier, "Courier New", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
}

html, body, #root {
  height: 100%;
}

html {
  color: #222;
  background: #fff;
  cursor: default;
}

body {
  margin: 0;
}

button {
  font-family: inherit;
  cursor: pointer;
}
